<template>
  <router-view />
</template>

<script>
export default {
  name: "Builder",
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
  },
  methods: {
    async loadGlobalData() {
      let globalData = await this.$axios({
        data: {
          query: `
          {
            pdgaEventTypes{
              name
              type
            }
            tourTypes{
              name
              type
            }
            divisionTypes{
              position
              name
              type
            }
            currencies{
              name
              code
              digits
              isEnabledForPayments
            }
          }
          `,
        },
      });

      this.$store.dispatch("updateGlobalData", globalData.data.data);
      this.loaded = true;
    },
  },
  mounted() {
    this.loadGlobalData();
  },
  beforeMount() {
    if (this.isUserLoggedIn == false) {
      this.$router.push({ name: "login" });
    }
    this.$store.dispatch("loadUnfinishedBuild");

    window.addEventListener("beforeunload", (e) => {
      this.$store.dispatch("saveUnfinishedBuild");
    });
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
</style>
